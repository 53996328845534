@tailwind base;
@tailwind components;
@tailwind utilities;

/*--global--*/
* {
  --diff: calc(var(--max-size) - var(--min-size));
  --responsive: calc((var(--min-size) * 1px) + var(--diff) * ((100vw - 320px) / (2000 - 320)));
}

.bluractive {
  filter: blur(7px);
}

.back {
  background-color: aliceblue;
}

.footer-menu li a:hover,
.bg00476D {
  background: #02283c;
}

header.fixed-header li a {
  color: #000 !important;
}

section {
  padding-top: 8%;
  padding-bottom: 8%;
}

.overlay-before:before,
.overlay-after:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s;
}

.footer-menu li a::before {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #ff3e3a;
  top: 15px;
  left: 8px;
}

/* .cros{
  margin-top: 100px !important
}
.menu-r-btn{
  margin-top: 67px !important
}
.toggle{
  margin-top: 67px !important
} */

/* --------------------------- */

/*--global--*/
* {
  --diff: calc(var(--max-size) - var(--min-size));
  --responsive: calc((var(--min-size) * 1px) + var(--diff) * ((100vw - 320px) / (2000 - 320)));
}

h1 {
  --max-size: 69;
  --min-size: 30;
  font-size: var(--responsive) !important;
  line-height: 80px;
}

h2 {
  --max-size: 45;
  --min-size: 23;
  font-size: var(--responsive) !important;
  line-height: 50px;
}

h3 {
  --max-size: 50;
  --min-size: 20;
  font-size: var(--responsive) !important;
}

h4 {
  --max-size: 20;
  --min-size: 15;
  font-size: var(--responsive) !important;
}

h5 {
  --max-size: 23;
  --min-size: 18;
  font-size: var(--responsive) !important;
}

h6 {
  --max-size: 14;
  --min-size: 13;
  font-size: var(--responsive) !important;
}

p,
.ptag {
  --max-size: 19;
  --min-size: 16;
  font-size: var(--responsive) !important;
  line-height: 28px;
}

a {
  --max-size: 19;
  --min-size: 15;
  font-size: var(--responsive);
  transition: all 0.5s;
}

.transition,
.footer-menu li a::before {
  transition: all 0.5s !important;
}

.kanitfont,
body {
  font-family: "Nunito", sans-serif;
}

.bg00517C {
  background: #003450;
}

.color00517C {
  color: #1f0757;
}

.border1f0757 {
  border-color: #003450;
}

.color5a5783 {
  color: #6b688d;
}

.border00517C {
  border-color: #00517c;
}

.d-global-btn .bg00517C:hover,
.d-table .bg00517C:hover {
  background: transparent;
  color: #00517c;
}

.bg3B88F5 {
  background: #3b88f5;
}

.color3B88F5 {
  color: #3b88f5;
}

.border3B88F5 {
  border-color: #3b88f5;
}

.bgFF3E3A,
.headertop li a:hover span:before {
  background: #ff3e3a;
}

.colorFF3E3A,
.headertop li a:hover,
header.fixed-header li a:hover {
  color: #ff3e3a !important;
}

.borderFF3E3A {
  border-color: #ff3e3a;
}

.bg808291 {
  background: #808291;
}

.color808291 {
  color: #808291;
}

.border808291 {
  border-color: #808291;
}

.bg808291 {
  background: #808291;
}

.bgFFF2FB {
  background: #fff2fb;
}

.bgF7F7F7,
.pricing-section ul li a.active {
  background: #f2f3fa;
}

.bgFEECEE {
  background: #feecee;
}

.colorC54942 {
  color: #c54942;
}

.bgfbe0f3 {
  background: #fddbf3;
}

.bgfde4e7 {
  background: #f7d9dd;
}

.bgffe9ec {
  background: #e2e6ff;
}

.bgFF3E3A.border:hover {
  background: #fff;
  color: #ff3e3a;
}

.footer-menu li a:hover,
.bg00476D {
  background: #02283c;
}

header.fixed-header li a {
  color: #fff !important;
}

section {
  padding-top: 8%;
  padding-bottom: 8%;
}

.overlay-before:before,
.overlay-after:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s;
}

/*--global--*/

/*--header--*/
.banner-video::before {
  width: 100%;
  height: 100%;
  /* background-image: url(../images/header/video-dot.png); */
  background-size: cover;
}

.headertop li span:before {
  width: 0%;
  bottom: 20px;
  top: auto;
  background: #00517c;
  height: 2px;
  transform: translate(-50%, 0px);
  left: 50%;
}

.headertop li a:hover span:before {
  width: 100%;
}

header.overlay-before:before {
  background-image: url(../src/assets/img/header.png);
  width: 1526px;
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  left: 50%;
  transform: translate(-50%, -45px);
  transition: all 0s !important;
}

header.fixed-header.overlay-before:before {
  opacity: 0;
}

/*--header--*/

/*--banner--*/
.videocol {
  width: 700px;
  height: 700px;
}

.banner-bg::before {
  /* background: url(../images/banner/banner-vi-img.jpg); */
  width: 100%;
  height: 100%;
  left: auto;
  right: 0;
  background-size: cover;
}

.banner-bg::after {
  background: #3b88f5e3;
  width: 100%;
  height: 100%;
}

.playbtn {
  font-size: 20px;
  width: 70px;
  height: 70px;
}

/*--banner--*/

/*--about--*/
.about-section .a-top-img {
  height: 520px;
}

.about-section .a-button-img {
  height: 280px;
}

.info-article:hover i {
  transform: scale(1.2);
}

/*--about--*/
.shadow {
  display: none !important;
}

.pricing-b-cover .w-full,
.contactform input,
.contactform textarea {
  box-shadow: 5px 5px 20px #cccccc61;
}

.footer-menu li a::before {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #ff3e3a;
  top: 15px;
  left: 8px;
}

.banner-video.hide_button .playbtn {
  display: none;
}

.video-t-section .banner-video {
  width: 100% !important;
  border: 1px solid #ffb5e9;
}

.video-col-box::before {
  background: #f2f3fa;
  width: 550px;
  height: 550px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.video-t-section .banner-video,
.video-t-section .banner-video video {
  height: 450px !important;
}

.features-section .overlay-before:before {
  background: #fcfcff;
  width: 100%;
  height: 80%;
  z-index: -1;
  border: 2px solid #f2f3fa;
  top: auto;
  bottom: -22px;
  border-radius: 20px;
}

.banner-section a.bg00517C:hover {
  background: #fff !important;
  color: #003450;
}

.contactus-section .slick-track {
  gap: 20px;
  display: flex;
}

/*--min-max-responsive--*/
@media only screen and (min-width: 1320px) and (max-width: 7000px) {
  .container-fluid {
    max-width: 1280px !important;
    max-width: 1280px !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 1320px) {
  .container-fluid {
    max-width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1700px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1550px) {
  header.overlay-before:before {
    width: 100%;
    height: 116px;
    transform: translate(-50%, -32px);
  }

  .banner-section .videocol {
    width: 600px;
    height: 600px;
  }
}

@media only screen and (max-width: 1400px) {
  header.inner-header.overlay-before:before {
    width: 100%;
    backdrop-filter: blur(5px) !important;
    background: rgb(255 255 255 / 58%);
  }

  header.overlay-before:before {
    background: #f2f3fa !important;
  }
}

@media only screen and (max-width: 1250px) {
  h1 {
    line-height: 60px;
  }

  .banner-section .videocol {
    width: 500px;
    height: 500px;
  }
}

@media only screen and (max-width: 1200px) {

  .video-t-section .banner-video,
  .video-t-section .banner-video video {
    height: 400px !important;
  }

  .video-col-box::before {
    background: #fff2fb;
    width: 500px;
    height: 500px;
  }

  p,
  .ptag {
    line-height: 25px;
  }

  h2 {
    line-height: 40px;
  }

  .about-section .a-top-img {
    height: 450px;
  }
}

@media only screen and (max-width: 1024px) {
  header.overlay-before:before {
    height: 98px;
  }

  .video-col-box::before {
    width: 400px;
    height: 400px;
  }

  .video-t-section .banner-video,
  .video-t-section .banner-video video {
    height: 350px !important;
  }

  h1 {
    line-height: 50px;
  }

  .banner-section .videocol {
    width: 450px;
    height: 450px;
  }

  .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.fixed-header {
  position: fixed !important;
  background: #003450;
  z-index: 99 !important;
}

.fixed-header img.re-logo-b-o,
.in-page .re-logo-b-o {
  display: none;
}

.fixed-header img.re-logo-b-w,
.in-page .re-logo-b-w {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .toggle.open span:nth-child(1) {
    width: 30px;
    transform: translateY(0) rotate(45deg);
    transition-delay: 0.125s;
  }

  .menu.homenav li {
    width: 100%;
    margin: 0 !important;
  }

  .menu.homenav li a {
    width: 100%;
    display: block;
    padding: 5px 15px !important;
    color: #00517c !important;
    font-weight: 500;
    background: transparent !important;
    border: 0;
    font-size: 17px;
  }

  .menu.homenav li a span.overlay-before {
    display: none;
  }

  .menu.homenav li a:hover {
    background: #003450 !important;
    color: #fff !important;
  }

  .menu.homenav ul {
    margin: 0 !important;
    padding: 0;
  }

  .menu.homenav {
    border-bottom: 1px solid #e7e9f6;
    background: #fff;
    margin-top: -1px;
    z-index: 9;
  }

  .toggle span:nth-child(1) {
    transform: translateY(-8px);
  }

  .toggle span:nth-child(2) {
    transform: translateY(8px);
  }

  .toggle.open span:nth-child(2) {
    width: 30px;
    transform: translateY(0) rotate(315deg);
    transition-delay: 0.25s;
  }

  .toggle.open span:nth-child(3) {
    transform: translateX(60px);
  }
}

@media only screen and (max-height: 700px) {
  .contactus-section .h-screen {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 900px) {

  .about-section .a-top-img,
  .about-section .a-button-img {
    height: auto;
  }
}

@media only screen and (max-width: 950px) {
  .video-col-box::before {
    width: 100%;
    height: 110%;
  }

  .video-t-section .banner-video,
  .video-t-section .banner-video video {
    height: auto !important;
  }

  .banner-section .c-logo .slick-arrow,
  .features-section .overlay-before:before {
    display: none !important;
  }

  .pricing-b-cover .w-full {
    box-shadow: 5px 5px 20px #cccccc61;
    width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .title-border.w-28 {
    margin: -5px 0 10px;
  }

  .headertop .toggle {
    transform: scale(0.9) translate(0px, 0px);
  }

  p,
  .ptag {
    line-height: 22px;
  }

  section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h2 {
    line-height: 30px;
  }

  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .left-baar-menu .toggle {
    transform: scale(0.8) translate(0px, 5px);
  }

  .Services-sec .infoatitle h4 {
    font-size: 16px !important;
  }

  h2 {
    line-height: 27px;
  }

  .banner-section .c-logo {
    width: auto !important;
  }

  h1 {
    line-height: 40px;
  }

  .pricing-b-cover .w-full {
    width: 100%;
  }

  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .headertop button {
    padding-right: 0 !important;
  }

  .banner-section .videocol {
    width: 380px;
    height: 380px;
  }

  .playbtn {
    font-size: 18px;
    width: 55px;
    height: 55px;
  }
}

@media only screen and (max-width: 400px) {
  .banner-section .videocol {
    width: 340px;
    height: 340px;
  }
}

@media only screen and (max-width: 450px) {
  .footer-menu li a::before {
    top: 10px;
    left: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .banner-section .videocol {
    width: 300px;
    height: 300px;
  }

  .playbtn {
    font-size: 16px;
    width: 47px;
    height: 47px;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #00517c;
}

::-webkit-scrollbar-thumb:hover {
  background: #00517c;
}

/* ----1-8-2024--- */

.text-border-c::before {
  width: 100%;
  height: 0.1px;
  background: #f0f1f1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-r-btn button:nth-child(2) {
  border-top-right-radius: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 100px;
}

.banner-video::before {
  width: 100%;
  height: 100%;
  /* background-image: url(../images/header/video-dot.png); */
  background-size: cover;
}

.headertop li span:before {
  width: 0%;
  bottom: 20px;
  top: auto;
  background: #00517c;
  height: 2px;
  transform: translate(-50%, 0px);
  left: 50%;
}

.headertop li a:hover span:before {
  width: 100%;
}

header.overlay-before:before {
  background-image: url(./assets/img/header.png);
  /* background-color: aliceblue; */
  width: 1526px;
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  left: 50%;
  transform: translate(-50%, -45px);
  transition: all 0s !important;
}

header.fixed-header.overlay-before:before {
  opacity: 0;
}

/*--header--*/

/*--banner--*/
.videocol {
  width: 700px;
  height: 700px;
}

.banner-bg::before {
  /* background: url(../images/banner/banner-vi-img.jpg); */
  width: 100%;
  height: 100%;
  left: auto;
  right: 0;
  background-size: cover;
}

.banner-bg::after {
  /* background: #3b88f5e3; */
  width: 100%;
  height: 100%;
}

.playbtn {
  font-size: 20px;
  width: 70px;
  height: 70px;
}

/*--banner--*/

/*--about--*/
.about-section .a-top-img {
  height: 520px;
}

.about-section .a-button-img {
  height: 280px;
}

.info-article:hover i {
  transform: scale(1.2);
}

/*--about--*/
.shadow {
  display: none !important;
}

.pricing-b-cover .w-full,
.contactform input,
.contactform textarea {
  box-shadow: 5px 5px 20px #cccccc61;
}

.footer-menu li a::before {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #ff3e3a;
  top: 15px;
  left: 8px;
}

.banner-video.hide_button .playbtn {
  display: none;
}

.video-t-section .banner-video {
  width: 100% !important;
  border: 1px solid #ffb5e9;
}

.video-col-box::before {
  background: #f2f3fa;
  width: 550px;
  height: 550px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.video-t-section .banner-video,
.video-t-section .banner-video video {
  height: 450px !important;
}

.features-section .overlay-before:before {
  background: #fcfcff;
  width: 100%;
  height: 80%;
  z-index: -1;
  border: 2px solid #f2f3fa;
  top: auto;
  bottom: -22px;
  border-radius: 20px;
}

.banner-section a.bg00517C:hover {
  background: #fff !important;
  color: #003450;
}

.contactus-section .slick-track {
  gap: 20px;
  display: flex;
}

/*--min-max-responsive--*/
@media only screen and (min-width: 1320px) and (max-width: 7000px) {
  .container-fluid {
    max-width: 1280px !important;
    max-width: 1280px !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 1320px) {
  .container-fluid {
    max-width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1700px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1550px) {
  header.overlay-before:before {
    width: 100%;
    height: 116px;
    transform: translate(-50%, -32px);
  }

  .banner-section .videocol {
    width: 600px;
    height: 600px;
  }
}

@media only screen and (max-width: 1400px) {
  header.inner-header.overlay-before:before {
    width: 100%;
    backdrop-filter: blur(5px) !important;
    background: rgb(255 255 255 / 58%);
  }

  header.overlay-before:before {
    background: #f2f3fa !important;
  }
}

@media only screen and (max-width: 1250px) {
  h1 {
    line-height: 60px;
  }

  .banner-section .videocol {
    width: 500px;
    height: 500px;
  }
}

@media only screen and (max-width: 1200px) {

  .video-t-section .banner-video,
  .video-t-section .banner-video video {
    height: 400px !important;
  }

  .video-col-box::before {
    background: #fff2fb;
    width: 500px;
    height: 500px;
  }

  p,
  .ptag {
    line-height: 25px;
  }

  h2 {
    line-height: 40px;
  }

  .about-section .a-top-img {
    height: 450px;
  }
}

@media only screen and (max-width: 1024px) {
  header.overlay-before:before {
    height: 98px;
  }

  .video-col-box::before {
    width: 400px;
    height: 400px;
  }

  .video-t-section .banner-video,
  .video-t-section .banner-video video {
    height: 350px !important;
  }

  h1 {
    line-height: 50px;
  }

  .banner-section .videocol {
    width: 450px;
    height: 450px;
  }

  .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.fixed-header {
  position: fixed !important;
  background: #003450;
  z-index: 99 !important;
}

.fixed-header img.re-logo-b-o,
.in-page .re-logo-b-o {
  display: none;
}

.fixed-header img.re-logo-b-w,
.in-page .re-logo-b-w {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .menu.homenav li {
    width: 100%;
    margin: 0 !important;
  }

  .menu.homenav li a {
    width: 100%;
    display: block;
    padding: 5px 15px !important;
    color: #00517c !important;
    font-weight: 500;
    background: transparent !important;
    border: 0;
    font-size: 17px;
  }

  .menu.homenav li a span.overlay-before {
    display: none;
  }

  .menu.homenav li a:hover {
    background: #003450 !important;
    color: #fff !important;
  }

  .menu.homenav ul {
    margin: 0 !important;
    padding: 0;
  }

  .menu.homenav {
    border-bottom: 1px solid #e7e9f6;
    background: #fff;
    margin-top: -1px;
    z-index: 9;
  }

  .toggle span:nth-child(1) {
    transform: translateY(-8px);
  }

  .toggle.active span:nth-child(1) {
    width: 30px;
    transform: translateY(0) rotate(45deg);
    transition-delay: 0.125s;
  }

  .toggle span:nth-child(2) {
    transform: translateY(8px);
  }

  .toggle.active span:nth-child(2) {
    width: 30px;
    transform: translateY(0) rotate(315deg);
    transition-delay: 0.25s;
  }

  .toggle.active span:nth-child(3) {
    transform: translateX(60px);
  }
}

@media only screen and (max-width: 900px) {

  .about-section .a-top-img,
  .about-section .a-button-img {
    height: auto;
  }
}

@media only screen and (max-width: 950px) {
  .video-col-box::before {
    width: 100%;
    height: 110%;
  }

  .video-t-section .banner-video,
  .video-t-section .banner-video video {
    height: auto !important;
  }

  .banner-section .c-logo .slick-arrow,
  .features-section .overlay-before:before {
    display: none !important;
  }

  .pricing-b-cover .w-full {
    box-shadow: 5px 5px 20px #cccccc61;
    width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .title-border.w-28 {
    margin: -5px 0 10px;
  }

  .headertop .toggle {
    transform: scale(0.9) translate(0px, 0px);
  }

  p,
  .ptag {
    line-height: 22px;
  }

  section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h2 {
    line-height: 30px;
  }

  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .left-baar-menu .toggle {
    transform: scale(0.8) translate(0px, 5px);
  }

  .Services-sec .infoatitle h4 {
    font-size: 16px !important;
  }

  h2 {
    line-height: 27px;
  }

  .banner-section .c-logo {
    width: auto !important;
  }

  h1 {
    line-height: 40px;
  }

  .pricing-b-cover .w-full {
    width: 100%;
  }

  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .headertop button {
    padding-right: 0 !important;
  }

  .banner-section .videocol {
    width: 380px;
    height: 380px;
  }

  .playbtn {
    font-size: 18px;
    width: 55px;
    height: 55px;
  }
}

@media only screen and (max-width: 400px) {
  .banner-section .videocol {
    width: 340px;
    height: 340px;
  }
}

@media only screen and (max-width: 450px) {
  .footer-menu li a::before {
    top: 10px;
    left: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .banner-section .videocol {
    width: 300px;
    height: 300px;
  }

  .playbtn {
    font-size: 16px;
    width: 47px;
    height: 47px;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #00517c;
}

::-webkit-scrollbar-thumb:hover {
  background: #00517c;
}

/* ----1-8-2024--- */

.text-border-c::before {
  width: 100%;
  height: 0.1px;
  background: #f0f1f1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bge7e9f6,
.left-menu ul li a:hover,
.header-baar .top-heder-drop select {
  background: #f2f3fa;
}

.bordere7e9f6 {
  border-color: #e7e9f6;
}

.left-menu ul li a::before {
  width: 0px;
  height: 100%;
  background: #003450;
  left: auto;
  right: 0;
  transition: all 0.1s;
}

.left-menu ul li a:hover::before {
  width: 5px;
}

.header-r-menu ul li a::before {
  height: 100%;
  width: 1px;
  background: #edeef8;
  left: -11px;
  top: 0;
}

.header-r-menu ul li:nth-child(1) a:before {
  display: none;
}

.right-baar-div .top-heder-drop select,
.d-table.h-connect-table th select {
  color: #003450;
  border-radius: 100px;
  padding: 3px 10px;
  font-weight: 600;
  font-size: 14px;
  outline: none !important;
}

.c-padding-r {
  padding-left: 315px;
}

.d-info-box .dec {
  max-height: 110px;
  overflow: auto;
}

.right-baar-div .d-table {
  max-height: 30vh;
  overflow: auto;
}

/* .right-baar-div .d-table tr:nth-child(1) {position: sticky;top: -1px;background: #f2f3fa; z-index: 9;} */

.top-baar-table {
  position: sticky;
  top: -1px;
  background: #f2f3fa;
  z-index: 9;
}

.right-baar-div .h-connect-table {
  max-height: 66vh;
}

.bluractive {
  filter: blur(7px);
}

.mobile-icon {
  display: none !important;
}

.pricing-b-cover .w-full {
  width: 33%;
}

.PersonalDetails-card {
  width: 100% !important;
}

@media only screen and (max-width: 1710px) {
  .pricing-b-cover .w-full {
    width: 31%;
  }

  .right-baar-div .h-connect-table table {
    width: 750px;
  }
}

@media only screen and (max-width: 1590px) {
  .personalize-table table {
    width: 435px !important;
  }
}

@media only screen and (max-width: 1550px) {
  .rightside {
    width: 58% !important;
  }

  .right-baar-div .h-connect-table {
    max-height: 59vh;
  }
}

@media only screen and (max-width: 1400px) {
  .c-padding-r {
    padding-left: 280px;
  }
}

@media only screen and (max-width: 1150px) {
  .pricing-b-cover .flex.gap-6 {
    justify-content: center;
  }

  .pricing-b-cover .w-full {
    width: 48%;
  }

  .c-padding-r {
    padding-left: 1.75rem;
  }

  .l-menu-o {
    transform: translateX(0) !important;
  }

  .left-baar-menu .toggle span:nth-child(1) {
    transform: translateY(-8px);
  }

  .left-baar-menu .toggle span:nth-child(2) {
    transform: translateY(8px);
  }

  .left-baar-menu .toggle.active span:nth-child(1) {
    width: 30px;
    transform: translateY(0) rotate(45deg);
    transition-delay: 0.125s;
  }

  .left-baar-menu .toggle.active span:nth-child(2) {
    width: 30px;
    transform: translateY(0) rotate(315deg);
    transition-delay: 0.25s;
  }

  .left-baar-menu .toggle.active span:nth-child(3) {
    transform: translateX(60px);
  }
}

@media only screen and (max-width: 1024px) {
  .rightside {
    width: 56% !important;
  }
}

@media only screen and (max-width: 703px) {
  .pricing-b-cover .w-full {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .rightside {
    width: 100% !important;
  }

  .right-baar-div .h-connect-table table {
    width: 800px;
  }
}

@media only screen and (max-width: 750px) {
  .personalize-table table {
    width: 100% !important;
  }
}

@media only screen and (max-width: 650px) {
  .personalize-table table {
    width: 585px !important;
  }

  .c-padding-r {
    padding-left: 0.75rem;
  }
}

@media only screen and (max-width: 500px) {
  .personalize-table table {
    width: 430px !important;
  }

  .mobile-icon {
    display: flex !important;
  }

  .right-baar-div .h-connect-table table {
    width: 740px;
  }

  .right-baar-div .top-heder-drop select {
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px) {}